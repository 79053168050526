<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import moment from 'moment'
import { getAction } from '@/command/netTool'

export default {
  name: 'application',
  data() {
    return {
      ...api.command.getState(),
      sysCodeArr: []
    }
  },
  mounted() {
    getAction('/api/app/querySysList').then(res => {
      if (res.data instanceof Array) {
        this.sysCodeArr = res.data.map(e => {
          return {
            name: e.desc,
            value: e.code
          }
        })
      }
    })
    api.command.getList.call(this, {
      url: '/app/page',
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '应用名称',
          type: 'input',
          key: 'appName'
        },
        {
          name: '应用编码',
          type: 'input',
          key: 'appCode'
        },
        {
          name: '所属系统',
          type: 'select',
          key: 'sysCode',
          typeData: this.sysCodeArr
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'appId',
          title: 'ID',
          type: 'lt-90',
          isId: true,
          sorter: (a, b) => a.appId - b.appId
        },
        {
          dataIndex: 'appImg',
          title: '应用图片',
          type: 'image',
          align: 'center',
          width: 80,
          isId: true
        },
        {
          dataIndex: 'appIcon',
          title: '应用图标',
          type: 'image',
          align: 'center',
          width: 80,
          isId: true
        },
        {
          dataIndex: 'appName',
          title: '应用名称',
          filter: true,
          type: 'lt-100',
          sorter: (a, b) => a.appName.length - b.appName.length
        },
        {
          dataIndex: 'appCode',
          title: '应用编码',
          type: 'lt-100',
          sorter: (a, b) => a.appCode.length - b.appCode.length
        },
        {
          dataIndex: 'sort',
          title: '排序',
          type: 'lt-100',
          sorter: (a, b) => a.sort - b.sort
        },
        {
          dataIndex: 'remark',
          title: '备注',
          type: 'lt-100',
          sorter: (a, b) => a.remark.length - b.remark.length
        },
        {
          dataIndex: 'createBy',
          isId: true,
          title: '创建人',
          type: 'lt-100',
          sorter: (a, b) => a.createBy.length - b.createBy.length
        },
        {
          dataIndex: 'createTime',
          isId: true,
          title: '创建时间',
          type: 'lt-100',
          sorter: (a, b) => moment(a.createTime).unix() - moment(b.createTime).unix()
        },
        {
          dataIndex: 'updateBy',
          isId: true,
          title: '修改人',
          type: 'lt-100',
          sorter: (a, b) => a.updateBy.length - b.updateBy.length
        },
        {
          dataIndex: 'updateTime',
          isId: true,
          title: '修改时间',
          type: 'lt-100',
          sorter: (a, b) => moment(a.updateTime).unix() - moment(b.updateTime).unix()
        },

        {
          dataIndex: 'status',
          title: '状态',
          width: '60px',
          type: 'badge',
          onExport: records => {
            return ['正常', '失效'][records]
          },
          filters: [
            {
              text: '正常',
              value: '0'
            },
            {
              text: '失效',
              value: '1'
            }
          ],
          onFilter: (value, record) => record.status == value,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '正常' : '失效',
              color: data == 0 ? 'green' : 'red'
            }
          }
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          width: '10%',
          typeData: ({ records }) => {
            return [
              {
                name: records.status == 0 ? '失效' : '正常',
                onClick: () => {
                  api.command.edit.call(this, {
                    url: `/app/${records.status == 0 ? 'disable' : 'enable'}?appId=${records.appId}`
                  })
                }
              },
              {
                name: '编辑',
                onClick: () => this.$router.push(`/system/applicationDetail?id=${records.appId}`)
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/app/${records.appId}`
                  })
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/system/applicationDetail')
        }
        // {
        //   viewType: 'menu',
        //   name: '更多操作',
        //   isBatch: true,
        //   children: [
        //     {
        //       name: '批量删除',
        //       onClick: data => {
        //
        //       }
        //     }
        //   ]
        // }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
